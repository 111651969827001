import React, { useState } from 'react';
import MainNav from '../Components/MainNav.js';
import Productitem from '../Components/ProductItem';
import FooterCart from '../Components/Footers';
import FiltersSection from 'registration/Components/FiltersSection.js';
// import SelectOptions from 'registration/Components/SelectOptions.js';
import { useCartOverview, useCartSummary, useSportEventList ,useTournamentDetails } from 'registration/queries/hooks.js';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { currentFormNumber } from 'registration/slices/registrationStepperSlice';
import ListLoader from 'components/ListLoader/index.js';
import FiltIcon from '../Components/FilterIcon';
import Loader from 'components/Loader/index.js';
import { useRegistrationFormJson } from '../queries/hooks';
// import { useMutateRemoveCartItem } from 'registration/queries/mutations.js';
import { useUserProfile } from '../../profiles/queries/profileHooks';
import { roundOfToDigit, roundOfToInt } from 'utils/formattingUtils';
// import { MdOutlineSportsHandball } from "react-icons/md";

export default function TournamentDetails() {
  const [gender, setGender] = useState('All');
  const [ageCategory, setAgeCategory] = useState('All');
  const [sport, setSport] = useState('All');
  const [sportCategory, setSportCategory] = useState('All');
  const [weightCategory, setWeightCategory] = useState('All');
  const [filterStatus, setFilterStatus] = useState(false);
  const cartOverviewSummary = useCartOverview();
  const params = useParams();
  const { data } = useUserProfile();
  const { search } = useLocation();
  const userPrimaryRole = data?.message?.primary_role;
  const inviteId = new URLSearchParams(search).get('invite_id');
  const sportEventListFilters = {
    tournament_id: params.tournament_id,
    gender: gender ? (gender === 'All' ? '' : gender) : '',
    age_category: ageCategory ? (ageCategory === 'All' ? '' : ageCategory) : '',
    sport: sport ? (sport === 'All' ? '' : sport) : '',
    sport_category: sportCategory ? (sportCategory === 'All' ? '' : sportCategory) : '',
    weight_category: weightCategory === 'All' ? '' : weightCategory,
  };
  const tournamentDetailsQueryData = useTournamentDetails({
    tournament_id: params.tournament_id,
  });

  console.log('tournamentDetailsQueryData', tournamentDetailsQueryData);

  const sportEventListQueryData = useSportEventList(sportEventListFilters);

  // const {
  //   data: { message: userProfile },
  // } = useUserProfile();

  const cartSummary = useCartSummary();

  const registrationFormJsonQueryData = useRegistrationFormJson({
    tournament_id: params.tournament_id,
    invite_id: '',
  });

  const filterStatusHandler = () => {
    setFilterStatus(!filterStatus);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <MainNav />
      <div className='md:mx-20 md:my-10 mx-4' >
      <div className="w-full  mx-auto px-4 md:px-20  pt-8 pb-12 bg-white shadow-md">
        {/* Header */}
        <h2 className="text-3xl font-bold text-indigo-600 mb-2">Basic Information</h2>
        <div className="w-20 h-1 bg-indigo-600 mb-8"></div>
  
        {/* Info Grid */}
        <div className="w-full flex flex-col md:flex-row md:justify-between gap-4">
          
          {/* Sport Name */}
          <div className="flex items-center">
            <div className="bg-indigo-100 p-3 rounded-full">
              {/* <MdOutlineSportsHandball className="text-indigo-600 text-2xl" /> */}
            </div>
            <div className="ml-4">
              <h4 className="text-indigo-400 text-3xl">Sport Name</h4>
              <p className="text-xl text-indigo-600 font-bold">
                {/* {tournamentDetailsQueryData?.data?.tournament_name?.split(' - ')[0]} */}
                {tournamentDetailsQueryData?.data?.sportname}
              </p>
            </div>
          </div>
          
          {/* Date Range */}
          <div className="flex items-center">
            <div className="bg-indigo-100 p-3 rounded-full">
              {/* <MdOutlineDateRange className="text-indigo-600 text-2xl" /> */}
            </div>
            <div className="ml-4">
              <h4 className="text-indigo-400 text-3xl">Sport Date</h4>
              <p className="text-xl text-indigo-600 font-bold">
          From {new Date(tournamentDetailsQueryData?.data?.tournament_regi_start_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} - 
          To {new Date(tournamentDetailsQueryData?.data?.tournament_regi_end_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
        </p>
            </div>
          </div>
          
          {/* Tournament Venue */}
          <div className="flex items-center">
            <div className="bg-indigo-100 p-3 rounded-full">
              {/* <FaBuilding className="text-indigo-600 text-2xl" /> */}
            </div>
            <div className="ml-4">
              <h4 className="text-indigo-400 text-3xl">Tournament Venue</h4>
              <p className="text-xl text-indigo-600 font-bold">
                {tournamentDetailsQueryData?.data?.city}
              </p>
            </div>
            
          </div>
  {/* Registration Fee */}  
            <div className="flex items-center">
            <div className="bg-indigo-100 p-3 rounded-full">
              {/* <FaBuilding className="text-indigo-600 text-2xl" /> */}
            </div>
              {tournamentDetailsQueryData.data?.is_summercamp === 1 ? (
                <div className="ml-4">
                  <h4 className="text-indigo-400 text-3xl">Batch Size</h4>
                  <p className="text-xl text-indigo-600 font-bold">
                    {tournamentDetailsQueryData?.data?.batch_size}
                  </p>
                </div>
              ) : (
                <div className="ml-4">
                  <h4 className="text-indigo-400 text-3xl">Prize Money</h4>
                  <p className="text-xl text-indigo-600 font-bold">
                    {tournamentDetailsQueryData?.data?.price_info}
                  </p>
                </div>
              )}
            
          </div>
        </div>
      </div>      
      </div>
      <div className="relative bg-white md:bg-gray-100 md:mt-8 mb-8 md:mb-48 w-full md:w-11/12 font-roboto m-auto flex gap-4 md:gap-0 flex-col md:flex-row justify-around text-sm">
        <div
          className={`fixed md:relative ${
            filterStatus ? 'block shadow-shadowPopup' : 'hidden md:block'
          } w-full md:w-80 z-10 md:z-0 bottom-0 md:static`}
        >
          <FiltersSection
            gender={gender}
            ageCategory={ageCategory}
            setGender={setGender}
            setAgeCategory={setAgeCategory}
            sport={sport}
            setSport={setSport}
            sportCategory={sportCategory}
            setSportCategory={setSportCategory}
            weightCategory={weightCategory}
            setWeightCategory={setWeightCategory}
            filterStatusHandler={filterStatusHandler}
          />
        </div>
        <div className="bg-white rounded-lg px-4 md:py-8 mb-24 w-full md:w-10/12 md:border border-gray-200">
          <div className="flex flex-col md:flex-row justify-between">
            {sportEventListQueryData.data && sportEventListQueryData.data.length > 0 ? (
              <>
                {' '}
                <div className="hidden md:block">
                  <h2 className="font-bold text-xl">Sports Events</h2>
                  <p className="text-sm mt-2 mb-5 text-gray-500">
                    SELECT THE SPORT & EVENT YOU WISH TO PARTICIPATE IN
                  </p>
                </div>
              </>
            ) : null}

            <div className="flex justify-between md:justify-start my-2.5 items-center ">
              <h2 className="font-bold md:hidden">Sport Event List</h2>
              <div className="flex gap-6 items-center">
                <div className="md:hidden" onClick={filterStatusHandler}>
                  <FiltIcon
                    color={`${filterStatus ? 'rgba(93, 95, 239, 1)' : 'rgba(39, 39, 39, 0.38)'}`}
                  />
                </div>
              </div>
            </div>
          </div>
          {userPrimaryRole === 'athlete' && (
            <div
              className="py-3 px-5 mb-4 bg-blue-100 text-blue-900 text-sm rounded-md border border-blue-200"
              role="alert"
            >
              To View Age & Event Categories Guidelines:{' '}
              <a target="_blank" href="https://bit.ly/3KMbXnT" rel="noreferrer">
                Click Here
              </a>
            </div>
          )}
          <div
            className={`gap-2 md:gap-4 grid ${
              sportEventListQueryData.data && sportEventListQueryData.data.length > 0
                ? 'lg:grid-cols-2'
                : 'lg:grid-cols-1'
            } grid-cols-2 md:grid-cols-1 w-full`}
          >
            {sportEventListQueryData.data ? (
              sportEventListQueryData.data.length > 0 ? (
                <ListLoader queryData={sportEventListQueryData} queryKey="ïd">
                  {({ item, index }) => {
                    return (
                      <Productitem
                        sportEventListFilters={sportEventListFilters}
                        key={index}
                        sportName={item.sport}
                        eventName={item.sport_event}
                        icon=""
                        img={item.sport_image}
                        sportCategory={item.sport_category}
                        subCategory={item.sub_category}
                        cartSummary={cartSummary}
                        gender={item.gender}
                        ageGroup={item.age_group}
                        price={roundOfToInt(item.price * 1.18)}
                        batchnumber={item.batch_number}
                        days={item.days}
                        batchtime={item.batch_time}
                        duration={item.duration}
                        status={item.status}
                        statusDate={item.status_date}
                        teamsCreated={item.teams_created}
                        sportEventListQueryData={sportEventListQueryData}
                        cartOverviewSummary={cartOverviewSummary}
                        enabled={false}
                        statusMessage={item.status_msg}
                        refetch={sportEventListQueryData.refetch}
                      />
                    );
                  }}
                </ListLoader>
              ) : (
                <div className="flex flex-col items-center mt-16 text-center">
                  <p className="w-full text-center my-20 text-sm text-gray-750">
                    Your events for the Championship will be updated here
                  </p>
                  <p className="text-sm text-gray-650 w-2/5 mt-4 mb-96">
                    There was no sport event match to the filters applied. Remove some filters or
                    reset filters to get new results.
                  </p>
                </div>
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      <FooterCart
        events={cartOverviewSummary?.data?.item_count}
        amount={
          cartOverviewSummary?.data?.item_count === 0
            ? 0
            : roundOfToDigit(cartOverviewSummary?.data?.cart_total * 1.18, 1)
        }
        cartSummary={cartSummary}
        onClick={() =>
          history.push(
            `/${params.user}/registration/${params.tournament_id}/payment${
              inviteId ? `?invite_id=${inviteId}` : ''
            }`
          )
        }
        hideBack={!registrationFormJsonQueryData?.data?.length > 0}
        onPrevious={() => {
          dispatch(currentFormNumber());
          history.push(`/${params.user}/registration/${params.tournament_id}/tournament-form`);
        }}
        hideBackButton={true}
      />
    </>
  );
}
